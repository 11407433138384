<template>
  <b-row>
    <b-col xl="8" lg="12" md="12" sm="12" cols="12" class="mb-5">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
            <!--begin: Wizard Nav-->

            <div class="wizard-nav border-bottom">
              <div class="wizard-steps p-8 p-lg-10">
                <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                  <div class="wizard-label">
                    <i class="wizard-icon flaticon-bus-stop"></i>
                    <h3 class="wizard-title">1. Setup Event</h3>
                  </div>
                  <i class="wizard-arrow flaticon2-next"></i>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <i class="wizard-icon flaticon-list"></i>
                    <h3 class="wizard-title">2. Enter Details</h3>
                  </div>
                  <i class="wizard-arrow flaticon2-next"></i>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <i class="wizard-icon flaticon-responsive"></i>
                    <h3 class="wizard-title">3. Cover Image</h3>
                  </div>
                  <i class="wizard-arrow flaticon2-next"></i>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <i class="wizard-icon flaticon-map-location"></i>
                    <h3 class="wizard-title">4. Place of Event</h3>
                  </div>
                  <i class="wizard-arrow flaticon2-next"></i>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <i class="wizard-icon flaticon-squares-3"></i>
                    <h3 class="wizard-title">5. Detail Element</h3>
                  </div>
                  <i class="wizard-arrow flaticon2-next"></i>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <i class="wizard-icon flaticon-alert"></i>
                    <h3 class="wizard-title">6. Alert</h3>
                  </div>
                </div>
              </div>
            </div>

            <!--end: Wizard Nav-->

            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <b-form id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Setup Your Event
                    </h4>
                    <b-form-group class="text-muted" label="Event Name">
                      <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <b-form-input></b-form-input> </b-col
                      ></b-row>
                    </b-form-group>

                    <b-form-group class="text-muted" label="Start">
                      <b-row>
                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <b-form-datepicker></b-form-datepicker>
                        </b-col>

                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <b-form-timepicker locale="en"></b-form-timepicker>
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <b-form-group class="text-muted" label="Enable Event Applications from">
                      <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <b-form-datepicker></b-form-datepicker></b-col
                      ></b-row>
                    </b-form-group>
                    <b-form-group class="text-muted" label="Number of Participants (max.)">
                      <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <b-form-input type="number" class="mr-1"></b-form-input></b-col
                      ></b-row>
                    </b-form-group>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Enter Details
                    </h4>
                    <b-form-group class="text-muted">
                      <b-row>
                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">Price in €</label>

                          <b-form-input></b-form-input>
                        </b-col>
                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">Status</label>
                          <select class="custom-select" id="inputGroupSelect01">
                            <option value="1">Pending</option>
                            <option value="2">Active</option>
                            <option value="3">Completed</option>
                          </select>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group class="text-muted" label="End">
                      <b-row>
                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <b-form-datepicker></b-form-datepicker>
                        </b-col>

                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <b-form-timepicker locale="en"></b-form-timepicker
                        ></b-col>
                      </b-row>
                    </b-form-group>
                    <b-form-group class="text-muted" label="Closed">
                      <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <b-form-datepicker></b-form-datepicker>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group>
                      <b-row>
                        <b-col xl="9" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">Visibility</label>

                          <b-form-checkbox-group
                            v-model="selected"
                            :options="options"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                          ></b-form-checkbox-group> </b-col
                      ></b-row>
                    </b-form-group>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Cover Image
                    </h4>
                    <b-row>
                      <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                        <div class="image-input image-input-outline" :style="{ backgroundImage: `url(${photo})` }">
                          <div class="image-input-wrapper"></div>
                          <label
                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="change"
                            data-toggle="tooltip"
                            title=""
                          >
                            <i class="fa fa-pen icon-sm text-muted"></i>
                            <input type="file" accept=".png, .jpg, .jpeg" @change="onFileChange($event, detail)" />
                          </label>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Place of Event
                    </h4>
                    <b-form-group class="text-muted">
                      <b-row>
                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">Name</label>
                          <b-form-input></b-form-input>
                        </b-col>
                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">Street</label>

                          <b-form-input></b-form-input>
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <b-form-group class="text-muted">
                      <b-row>
                        <b-col xl="2" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">ZIP Code</label>
                          <b-form-input></b-form-input>
                        </b-col>
                        <b-col xl="4" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">City</label>

                          <b-form-input></b-form-input>
                        </b-col>
                        <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">Country</label>
                          <b-form-input></b-form-input>
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <b-row>
                      <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                        <sc-maps />
                      </b-col>
                    </b-row>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 5-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <b-row>
                      <b-col cols="11" lg="11" md="11" sm="11" xl="11" class="mb-5">
                        <h4 class="mb-10 font-weight-bold text-dark">
                          Detail Element
                        </h4>
                      </b-col>
                      <b-col cols="1" lg="1" md="1" sm="1" xl="1" class="text-right">
                        <b-button class="p-2 mr-5" v-b-tooltip.hover @click="add(details)" title="Add section"
                          ><i class="flaticon2-plus m-0 p-0"></i
                        ></b-button>
                      </b-col>
                    </b-row>

                    <b-form-group class="text-muted">
                      <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                          <b-alert
                            variant="warning"
                            dismissible
                            fade
                            :show="showAlert"
                            v-on:dismissed="showAlert = false"
                          >
                            {{ error }}
                          </b-alert>
                        </b-col></b-row
                      >
                      <b-row v-for="(detail, i) in details" v-bind:key="`detail_${i}`">
                        <b-col cols="12" lg="12" md="12" sm="12" xl="12" class="mb-5">
                          <div role="tablist">
                            <b-card no-body
                              ><b-card-header header-tag="header" class="p-1" role="tab">
                                <b-row>
                                  <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                                    <b-button
                                      block
                                      href="#"
                                      v-b-toggle="'collapse-' + i"
                                      variant="white"
                                      class="text-left d-flex align-items-center justify-content-between"
                                      >Detail {{ details.length - i }}

                                      <b-button class="p-2" v-b-tooltip.hover @click="remove(i)" title="Remove section"
                                        ><i class="flaticon2-cross m-0 p-0"></i
                                      ></b-button>
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-card-header>
                              <b-collapse :id="'collapse-' + i" accordion="reply_groups" role="tabpanel"
                                ><b-card-body>
                                  <b-row>
                                    <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5">
                                      <label class="text-muted">Subline</label>

                                      <b-form-input v-model="detail.subline"></b-form-input>
                                    </b-col>
                                    <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-10">
                                      <label class="text-muted">Headline</label>
                                      <b-form-input v-model="detail.headline"></b-form-input>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
                                      <ckeditor v-model="detail.description"></ckeditor></b-col
                                  ></b-row>
                                  <b-row>
                                    <label class="text-muted ml-5 mt-5">Image</label>
                                    <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="text-left mb-5">
                                      <div
                                        class="image-input image-input-outline"
                                        :style="{
                                          backgroundImage: `url(${detail.image})`,
                                        }"
                                      >
                                        <div class="image-input-wrapper text-left"></div>
                                        <label
                                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                          data-action="change"
                                          data-toggle="tooltip"
                                          title=""
                                        >
                                          <i class="fa fa-pen icon-sm text-muted"></i>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            @change="onFileChangeElementDetail($event, detail)"
                                          />
                                        </label>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mb-5 mt-5">
                                      <label class="text-muted">External Link</label>

                                      <b-form-input
                                        v-model="detail.external_link.linkText"
                                        placeholder="Link Text"
                                      ></b-form-input>
                                    </b-col>
                                    <b-col xl="6" lg="12" md="12" sm="12" cols="12" class="mt-13">
                                      <b-form-input v-model="detail.external_link.url" placeholder="URL"></b-form-input>
                                    </b-col>
                                  </b-row>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </div>
                  <!--end: Wizard Step 5-->

                  <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">Alert</h4>
                    <b-form-group class="text-muted">
                      <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-5">
                          <label class="text-muted">Title</label>

                          <b-form-input></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mt-5"
                          ><label class="text-muted">Description</label>
                          <ckeditor :v-model="editorData" :config="editorConfig"></ckeditor></b-col
                      ></b-row>
                    </b-form-group>
                  </div>

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-secondary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Previous
                      </button>
                    </div>
                    <div>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Submit
                      </button>
                      <button
                        class="btn btn-secondary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </b-form>
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div></b-col
    >

    <b-col xl="4" lg="12" md="12" sm="12" cols="12">
      <b-card>
        <b-card-header text-variant="dark" header="Create a new event?" header-tag="h3" header-text-variant="black">
        </b-card-header>

        <b-card-body>
          <b-card-text class="font-size-md">
            Here you can set up your new event with all important basic data and possible details. You do not have a
            suitable motif for your event?<br />
            No problem, alternatively a placeholder will be inserted automatically. When you have inserted all contents,
            simply save your event – if required, you can also send the invitation code directly to the possible
            participants.
          </b-card-text>
        </b-card-body>
        <b-row>
          <b-col xl="12" lg="12" md="12" sm="12" cols="12">
            <button type="button" class="btn btn-warning font-weight-bold px-8 py-4 my-3 w-100">
              Send and generate Invite Code.
            </button>
          </b-col>
          <b-col xl="12" lg="12" md="12" sm="12" cols="12">
            <button type="button" class="btn btn-secondary font-weight-bold px-8 py-4 my-3 w-100">
              Cancel
            </button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTUtil from '@/assets/js/components/util'
import KTWizard from '@/assets/js/components/wizard'
import { mapGetters } from 'vuex'
import ScMaps from '../../content/widgets/sc-maps.vue'
import CKEditor from 'ckeditor4-vue'

export default {
  name: 'events-new',
  data() {
    return {
      showAlert: false,
      error: '',
      editorData: '',
      editorConfig: {},
      details: [
        {
          subline: '',
          headline: '',
          description: '',
          image: '',
          external_link: {
            linkText: '',
            url: '',
          },
        },
      ],
      show: 'settings',
      selected: [],
      options: [
        { item: 'A', name: 'private' },
        { item: 'B', name: 'public' },
        { item: 'C', name: 'approval needed', notEnabled: true },
      ],
      default_photo: '',
      current_photo: null,
    }
  },
  computed: {
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo
    },
  },

  components: {
    ScMaps,
    ...mapGetters(['getErrors']),
    ckeditor: CKEditor.component,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Events' }])
    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v1', {
      startStep: 1, // initial active step number

      clickableSteps: true, // allow step clicking
    })

    // Validation before going to next page
    wizard.on('beforeNext', function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    })

    // Change event
    wizard.on('change', function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop()
      }, 500)
    })
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.current_photo = event.target.result
        }

        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    onFileChangeElementDetail(e, detail) {
      const file = e.target.files[0]

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          detail.image = event.target.result
        }

        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    add() {
      if (this.details.length == 10) {
        this.error = 'There should be a maximum of 10 detail items.'
        this.showAlert = 5
      } else {
        const detail = {
          subline: '',
          headline: '',
          description: '',
          image: '',
          external_link: {
            linkText: '',
            url: '',
          },
        }

        this.details = [detail, ...this.details]
      }
    },
    remove(i) {
      if (this.details.length == 1) {
        this.error = 'Must have at least one detail item.'
        this.showAlert = 5
      } else {
        this.details.splice(i, 1)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';
.btn-warning {
  background: #e45e00 !important;
}
</style>
