<template>
  <google-map id="map" ref="map" :zoom="15" @click="clicked">
    <google-map-marker :position="currentLocation"> </google-map-marker>
  </google-map>
</template>

<script>
export default {
  data() {
    return {
      currentLocation: null,
    }
  },
  methods: {
    getUserLocation() {
      navigator.geolocation.getCurrentPosition(position => {
        this.currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        this.$refs.map.setCenter(this.currentLocation)
      })
    },
    clicked(event) {
      const { lat, lng } = event.latLng
      this.currentLocation = {
        lat: lat(),
        lng: lng(),
      }
    },
  },
  mounted() {
    this.getUserLocation()
  },
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 500px;
}
</style>
